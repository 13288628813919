
.wrapper {
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    height: auto;
    position: relative;
}

.tilte-day {
    display: flex;
    flex-wrap: wrap;
    width: 5%;
    align-items: center;
}


.prize {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
}

.body-prize {
    display: flex;

}

.tilte-prize {
    width: 15%;
    display: flex;
    align-items: center;
}

.body-price {
    margin: 0 1.5rem;
    display: flex;
    flex-direction: column;
}

.body-row {
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
}

.body-row:first-child {
    margin-top: 0;
}

.money-round {
    /* margin-left: 4.5rem; */
    display: flex;
    width: 70%;
    align-items: center;
}

.body-money-round {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.ant-ribbon.ant-ribbon-placement-end {
    top: -10px !important;
}
.ant-ribbon-color-red{
    font-weight: 700 !important;
    
}
.ant-ribbon-text {
    font-size: 12px !important;    
}
.ant-input-number-input {
    text-align: center !important;
}